export const DISPLAY_ALERT = 'SHOW_ALERT'
export const CLEAR_ALERT = 'CLEAR_ALERT'
export const DISPLAY_DIALOG = 'DISPLAY_DIALOG'
export const HIDE_DIALOG = 'HIDE_DIALOG'
export const DELETE_RECORD_BEGIN = 'DELETE_RECORD_BEGIN'
export const DELETE_RECORD_SUCCESS = 'DELETE_RECORD_SUCCESS'

export const SETUP_USER_BEGIN = 'SETUP_USER_BEGIN'
export const SETUP_USER_SUCCESS = 'SETUP_USER_SUCCESS'
export const SETUP_USER_ERROR = 'SETUP_USER_ERROR'

export const LOGOUT_USER = 'LOGOUT_USER'

export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

export const HANDLE_CHANGE = 'HANDLE_CHANGE'
export const CLEAR_VALUES = 'CLEAR_VALUES'

export const CREATE_CATEGORY_BEGIN = 'CREATE_CATEGORY_BEGIN'
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS'
export const CREATE_CATEGORY_ERROR = 'CREATE_CATEGORY_ERROR'

export const GET_CATEGORY_BEGIN = 'GET_CATEGORY_BEGIN'
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS'

export const SET_EDIT_CATEGORY = 'SET_EDIT_CATEGORY'
export const DELETE_CATEGORY_BEGIN = 'DELETE_CATEGORY_BEGIN'

export const EDIT_CATEGORY_BEGIN = 'EDIT_CATEGORY_BEGIN'
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS'
export const EDIT_CATEGORY_ERROR = 'EDIT_CATEGORY_ERROR'

export const CREATE_CLIENT_BEGIN = 'CREATE_CLIENT_BEGIN'
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS'
export const CREATE_CLIENT_ERROR = 'CREATE_CLIENT_ERROR'

export const GET_CLIENT_BEGIN = 'GET_CLIENT_BEGIN'
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS'

export const SET_EDIT_CLIENT = 'SET_EDIT_CLIENT'
export const DELETE_CLIENT_BEGIN = 'DELETE_CLIENT_BEGIN'

export const EDIT_CLIENT_BEGIN = 'EDIT_CLIENT_BEGIN'
export const EDIT_CLIENT_SUCCESS = 'EDIT_CLIENT_SUCCESS'
export const EDIT_CLIENT_ERROR = 'EDIT_CLIENT_ERROR'

export const GET_USERLIST_BEGIN = 'GET_USERLIST_BEGIN'
export const GET_USERLIST_SUCCESS = 'GET_USERLIST_SUCCESS'

export const SET_USER_CLIENT = 'SET_USER_CLIENT'
export const CREATE_USER_CLIENT_BEGIN = 'CREATE_USER_CLIENT_BEGIN'
export const CREATE_USER_CLIENT_SUCCESS = 'CREATE_USER_CLIENT_SUCCESS'
export const CREATE_USER_CLIENT_ERROR = 'CREATE_USER_CLIENT_ERROR'

export const GET_USER_CLIENT_BEGIN = 'GET_USER_CLIENT_BEGIN'
export const GET_USER_CLIENT_SUCCESS = 'GET_USER_CLIENT_SUCCESS'

export const DELETE_USER_CLIENT_BEGIN = 'DELETE_USER_CLIENT_BEGIN'

export const GET_PROGRESS_CATEGORY_BEGIN = 'GET_PROGRESS_CATEGORY_BEGIN'
export const GET_PROGRESS_CATEGORY_SUCCESS = 'GET_PROGRESS_CATEGORY_SUCCESS'

export const FETCH_PROGRESS_BEGIN = 'FETCH_PROGRESS_BEGIN'
export const FETCH_PROGRESS_SUCCESS = 'FETCH_PROGRESS_SUCCESS'

export const CREATE_ADD_PROGRESS_BEGIN = 'CREATE_ADD_PROGRESS_BEGIN'
export const CREATE_ADD_PROGRESS_SUCCESS = 'CREATE_ADD_PROGRESS_SUCCESS'
export const CREATE_ADD_PROGRESS_ERROR = 'CREATE_ADD_PROGRESS_ERROR'

export const CREATE_SCHEDULE_BEGIN = 'CREATE_SCHEDULE_BEGIN'
export const CREATE_SCHEDULE_ERROR = 'CREATE_SCHEDULE_ERROR'
export const CREATE_SCHEDULE_SUCCESS = 'CREATE_SCHEDULE_SUCCESS'

export const GET_IND_SCHEDULE_BEGIN = 'GET_IND_SCHEDULE_BEGIN'
export const GET_IND_SCHEDULE_SUCCESS = 'GET_IND_SCHEDULE_SUCCESS'

export const GET_CAMPAIGN_TITLE_SUCCESS = 'GET_CAMPAIGN_TITLE_SUCCESS'
export const GET_EDM_TITLE_SUCCESS = 'GET_EDM_TITLE_SUCCESS'

export const EDIT_SCHEDULE_BEGIN = 'EDIT_SCHEDULE_BEGIN'
export const EDIT_SCHEDULE_ERROR = 'EDIT_SCHEDULE_ERROR'
export const EDIT_SCHEDULE_SUCCESS = 'EDIT_SCHEDULE_SUCCESS'

export const SETUP_USERCLIENT_SUCCESS = 'SETUP_USERCLIENT_SUCCESS'
export const SETUP_USERCLIENT_ERROR = 'SETUP_USERCLIENT_ERROR'

export const GET_CAMPAIGN_BEGIN = 'GET_CAMPAIGN_BEGIN'
export const GET_CAMPAIGN_SUCCESS = 'GET_CAMPAIGN_SUCCESS'

export const CREATE_CAMPAIGN_BEGIN = 'CREATE_CAMPAIGN_BEGIN'
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS'
export const CREATE_CAMPAIGN_ERROR = 'CREATE_CAMPAIGN_ERROR'

export const EDIT_CAMPAIGN_SUCCESS = 'EDIT_CAMPAIGN_SUCCESS'
export const EDIT_CAMPAIGN_ERROR = 'EDIT_CAMPAIGN_ERROR'

export const CREATE_SOCIAL_BEGIN = 'CREATE_SOCIAL_BEGIN'
export const CREATE_SOCIAL_SUCCESS = 'CREATE_SOCIAL_SUCCESS'
export const CREATE_SOCIAL_ERROR = 'CREATE_SOCIAL_ERROR'

export const GET_IND_SOCIAL_BEGIN = 'GET_IND_SOCIAL_BEGIN'
export const GET_IND_SOCIAL_SUCCESS = 'GET_IND_SOCIAL_SUCCESS'

export const EDIT_SOCIAL_SUCCESS = 'EDIT_SOCIAL_SUCCESS'
export const EDIT_SOCIAL_ERROR = 'EDIT_SOCIAL_ERROR'

export const GET_SOCIAL_TITLE_SUCCESS = 'GET_SOCIAL_TITLE_SUCCESS'

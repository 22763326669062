const PastelColours = [
  { code: 'AED9EA', name: 'Pale Blue' },
  { code: 'D7DBDA', name: 'Silver' },
  { code: 'D7E0E5', name: 'Light Grayish Blue' },
  { code: 'D9E6EC', name: 'Powder Blue' },
  { code: 'DEE9EB', name: 'Light Blue Gray' },
  { code: 'E7E7DB', name: 'Light Gray' },
  { code: 'EAE8EB', name: 'Lavender Gray' },
  { code: 'EAEEE0', name: 'Pale Green' },
  { code: 'EBF6FA', name: 'Light Azure' },
  { code: 'ECDCDC', name: 'Misty Rose' },
  { code: 'EDDFDE', name: 'Blush' },
  { code: 'EDECEB', name: 'Off-White' },
  { code: 'EEE8E8', name: 'Alabaster' },
  { code: 'EFDFD5', name: 'Linen' },
  { code: 'EFEFEF', name: 'Light Grayish White' },
  { code: 'E1E2E4', name: 'Pale Silver' },
  { code: 'F6F3EE', name: 'Light Beige' },
  { code: 'FAE9E2', name: 'Peach' },
  { code: 'FAF3EB', name: 'Vanilla' },
  { code: 'FBEFE3', name: 'Light Gold' },
  { code: 'F0EDE8', name: 'Light Pink' },
]

export default PastelColours
